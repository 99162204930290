/* src/App.css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  color: white;
  font-size: 3rem;
  font-family: 'Roboto', sans-serif;
}
h2 {
  color: white;
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
}

h2 {
  color: white;
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
}

.form-title {
  text-align: center;
  margin: 20px 0; 
  width: 100%; 
}

.form-container {
  flex: 1; 
  display: flex;
  flex-direction: column; 
  align-items: center; 
  padding: 20px;
}

.inicio-container {
  background-size: 100% 118%; 
  background-repeat: no-repeat; 
  background-position: center; 
  height: 100vh; 
  width: 100vw; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.circular-button {
  position: absolute;
  top: 20px; 
  right: 30px; 
  border-radius: 0%; 
  border: 2px solid white; 
  background-color: transparent;
  cursor: pointer; 
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; 
  font-size: 18px;
  margin-left: 12px; 
  transition: background-color 0.3s ease; 
  padding: 10px; 
  border-radius: 20px; 
  background-color: transparent;
  cursor: pointer; 
  width: 130px; 
  height: 40px; 
  transition: background-color 0.3s;
}

.circular-button:hover {
  background-color: rgba(255, 255, 255, 0.1); 
}
.inicio-sesion-container {
  display: flex; 
  height: 100vh; 
  background-color: rgb(157, 188, 201); 
}

.image-container {
  flex: 1.3;
  background-size: cover; 
  background-position: center; 
}

.form-container {
  flex: 1; 
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 20px; 
}

.form-box {
  background-color: rgba(255, 255, 255, 0.9); 
  padding: 40px; 
  border-radius: 10px; 
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
  width: 300px; 
  max-width: 100%; 
  display: flex; 
  flex-direction: column;
  justify-content: center; 
  align-items: center;
}

form {
  display: flex;
  flex-direction: column; 
  width: 100%; 
}

label {
  margin-bottom: 5px; 
  text-align: center; 
}

input {
  margin-bottom: 15px; 
  padding: 10px; 
  border: 1px solid #ccc; 
  border-radius: 6px; 
  width: 100%; 
}

button {
  padding: 10px; 
  border: 2px solid rgb(157, 188, 201); 
  border-radius: 20px; 
  background-color: transparent; 
  color: rgb(157, 188, 201); 
  cursor: pointer; 
  width: 100px; 
  height: 40px; 
  transition: background-color 0.3s; 
}

button:hover {
  background-color: rgba(0, 0, 255, 0.1); 
}

.button-container {
  display: flex; 
  gap: 10px; 
  margin-top: 15px; 
  justify-content: center; 
}

.suscripcion-container {
  display: flex;
  height: 100vh; /* Ocupa toda la altura de la pantalla */
}

.suscripcion-image {
  flex: 1; /* Ocupa la mitad de la pantalla */
}

.suscripcion-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que la imagen cubra toda la mitad sin deformarse */
}

.suscripcion-card {
  flex: 1; /* Ocupa la otra mitad de la pantalla */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #e0f2f1;
}

.suscripcion-card h1 {
  color: #3f51b5;
  font-size: 2em;
  margin-bottom: 10px;
}

.price-container {
  background-color: #3f51b5; /* Color de fondo para el precio */
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: center;
}

.features-container {
  background-color: #f0f4c3; /* Color de fondo para la lista de contenido */
  padding: 15px;
  border-radius: 8px;
  width: 50%;
  text-align: left;
  margin-bottom: 20px;
}

.features-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #333;
}

.features-container ul li {
  margin-bottom: 8px;
  font-size: 1em;
}

.pay-button, .paypal-button {
  width: 50%;
  background-color: #3f51b5;
  color: white;
  border: none;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.pay-button:hover, .paypal-button:hover {
  background-color: #303f9f;
}

.paypal-button {
  background-color: #ffc107;
}


.recuperarc-background {
  background-color: #e3f2fd; /* Azul claro */
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.recuperarc-container {
  background-color: #ffffff; /* Fondo blanco */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  text-align: center;
}

h2 {
  color: #1565c0; /* Azul oscuro */
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  font-size: 16px;
}

.boton-recuperacion {
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  background-color: #1976d2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0 auto;
 
}

button:hover {
  background-color: #1565c0;
  
}

p {
  margin-top: 10px;
  font-size: 14px;
  color: #333333;
}

.verificacion-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ocupa toda la altura de la pantalla */
  background-color: #e3f2fd; /* Fondo azul */
  color: #ffffff; /* Texto blanco */
}

.verificacion-form {
  background-color: #ffffff; /* Fondo blanco para el formulario */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para destacar el formulario */
  width: 100%;
  max-width: 400px; /* Limita el tamaño máximo del formulario */
  text-align: center; /* Centra el contenido del formulario */
  color: #000000; /* Texto negro dentro del formulario */
}

.verificacion-form input {
  width: calc(100% - 20px); /* Ajusta el ancho del input */
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.verificacion-form button {
  padding: 10px;
  font-size: 16px;
  color: #ffffff;
  background-color: #1976d2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Botones ocupan todo el ancho */
  margin: 0 auto;
}

.verificacion-form button:hover {
  background-color: #1565c0;

}


.verificacion-mensaje {
  margin-top: 15px;
  font-size: 14px;
  color: #0f0f0f;
}

